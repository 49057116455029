import { createGlobalStyle } from 'styled-components';

import neueHaasUnicaProRegularWoff from '@/assets/fonts/TimesNow-SemiLight.woff';
import neueHaasUnicaProRegularWoff2 from '@/assets/fonts/TimesNow-SemiLight.woff2';

import { palette, fonts } from '.';

export default createGlobalStyle`
  @font-face {
    font-family: SemiLight;
    font-display: swap;
    src: url(${neueHaasUnicaProRegularWoff2}) format("woff2"), url(${neueHaasUnicaProRegularWoff}) format("woff");
  }
  html {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100%;
    width: 100%;
    min-width: 350px;
  }
  body, #___gatsby, #gatsby-focus-wrapper {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    background-color: ${palette.white};
    margin: 0;
    padding: 0;
  }
  button, select {
    border: none;
    border-radius: 0;
    background: none;
    outline: none;
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
    }
  }
  input, textarea {
    outline: none;
    border-radius: 0;
    background: none;
    padding: 0;
  }
  a {
    text-decoration-thickness: 2.1px;
    @media (max-width: 991px) {
      text-decoration-thickness: 1.1px;
    }
  }
  * {
    ${fonts.smallText22}
    color: ${palette.black};
    margin: 0;
    padding: 0;
    border: none;
    appearance: none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    -webkit-overflow-scrolling: touch;
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;
