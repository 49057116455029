const React = require('react');

const GlobalStyle = require('@/theme/GlobalStyles').default;
const Layout = require('@/components/Layout').default;

exports.wrapPageElement = ({ element, props: { location } }) => (
  <>
    <GlobalStyle />
    <Layout uri={location.pathname}>{element}</Layout>
  </>
);
