import { useRef, useState, useEffect, useCallback, useContext, createContext } from 'react';

export const useAsyncState = (init) => {
  const isMounted = useRef(true);
  const [state, setState] = useState(init);

  const updateState = useCallback((data) => {
    if (isMounted.current) {
      setState(data);
    }
  }, []);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  return [state, updateState, isMounted.current];
};

export const ObserverContext = createContext();
export const useObserver = () => {
  const { id, setPosition } = useContext(ObserverContext);

  const checkPosition = useCallback(() => setPosition(), [setPosition]);

  useEffect(() => {
    if (typeof window === 'undefined') return () => null;
    window.addEventListener('resize', checkPosition);

    return () => {
      window.removeEventListener('resize', checkPosition);
    };
  }, [checkPosition]);

  return { id, setPosition };
};
