let observerEl;

const getElem = () => {
  if (!observerEl) observerEl = window?.document?.querySelector('#custom-observer-component');
  return observerEl;
};

export const init = {
  id: 'custom-observer-component',
  setPosition: (e) => {
    const elem = getElem();

    if (!(elem && window && document)) return;

    const goalElem = e?.currentTarget || e?.current;
    const mainElem = document.querySelector('main');
    const { top, height, bottom } = goalElem ? goalElem.getBoundingClientRect() : {};
    const { bottom: mainBottom } = mainElem ? mainElem.getBoundingClientRect() : {};
    const isMobile = window.innerWidth <= 991;
    const offset = isMobile ? 30 : 60;
    const scroll = window.scrollY || 0;

    if (goalElem && mainElem && height) {
      const correctTop = scroll + top - 2 * offset > 30 ? scroll + top - offset : offset;
      elem.style.top = `${correctTop}px`;

      const correctBottom = mainBottom - bottom - offset > 60 ? mainBottom - bottom : offset;
      elem.style.bottom = `${correctBottom}px`;
    } else {
      elem.style.top = `${offset}px`;
      elem.style.bottom = `${offset}px`;
    }
  },
};
