import styled from 'styled-components';
import { Link } from 'gatsby';

import { fonts } from '@/theme';

export const Container = styled.footer`
  flex: 0 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 60px;
`;

export const Item = styled(Link)`
  display: flex;
  align-items: center;
  width: 25%;
  height: 60px;
  box-shadow: ${({ disabled }) => (disabled ? 'inset 0 0 0 5px' : '-2px 0, inset 0 2px')};
  ${fonts.smallText22}
  text-decoration: none;
  padding: 0 22px;
  @media (max-width: 991px) {
    width: 50%;
    height: 30px;
    &:nth-child(1) {
      box-shadow: ${({ disabled }) => (disabled ? 'inset 0.5px 1px 0 3px' : 'inset -0.5px 1px 0 0.5px')};
    }
    &:nth-child(2) {
      box-shadow: ${({ disabled }) => (disabled ? 'inset -0.5px 1px 0 3px' : 'inset 0.5px 1px 0 0.5px')};
    }
    &:nth-child(3) {
      box-shadow: ${({ disabled }) => (disabled ? 'inset 0.5px 0 0 3px' : 'inset -0.5px 1px 0 0.5px')};
    }
    &:nth-child(4) {
      box-shadow: ${({ disabled }) => (disabled ? 'inset -0.5px 0 0 3px' : 'inset 0.5px 1px 0 0.5px')};
    }
    padding: 0 8px;
  }
`;
