import React from 'react';
import PropTypes from 'prop-types';

import { useObserver } from '@/hooks';

import { TopBorder, Content, Observer, BottomBorder } from './styles';

const Frame = ({ children }) => {
  const { id } = useObserver();

  return (
    <>
      <Observer id={id} />
      <TopBorder />
      <Content>{children}</Content>
      <BottomBorder />
    </>
  );
};

Frame.propTypes = { children: PropTypes.node.isRequired };

export default Frame;
