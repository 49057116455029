import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { ObserverContext } from '@/hooks';
import { init } from '@/utils/observer';

import Header from './Header';
import Frame from './Frame';
import Footer from './Footer';
import { Main } from './styles';

const Layout = ({ children, uri }) => {
  useEffect(() => {
    if (uri === '/goenner/schritt-1/' || uri === '/goenner/schritt-2/') return;

    init.setPosition();
  }, [uri]);

  return (
    <>
      <Header uri={uri} />
      <Main>
        <ObserverContext.Provider value={init}>
          <Frame>{children}</Frame>
        </ObserverContext.Provider>
      </Main>
      <Footer uri={uri} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  uri: PropTypes.string.isRequired,
};

export default Layout;
