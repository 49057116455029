import styled from 'styled-components';

export const Main = styled.main`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin: 60px auto 0;
  max-width: 1440px;
  overflow: hidden;
  @media (min-width: 1441px) {
    border-left: 2px solid #000;
    border-right: 2px solid #000;
  }
  @media (max-width: 991px) {
    margin-top: 30px;
  }
`;
