import styled from 'styled-components';

import { palette } from '@/theme';

const { black, white, red, gray } = palette;

export const TopBorder = styled.div`
  display: flex;
  justify-content: space-between;
  height: 60px;
  width: 100%;
  border-bottom: 2px solid ${black};
  &::before {
    content: '';
    height: 100%;
    width: 60px;
    border-right: 2px solid ${black};
  }
  &::after {
    content: '';
    height: 100%;
    width: 60px;
    border-left: 2px solid ${black};
  }
  @media (max-width: 991px) {
    height: 30px;
    border-bottom: 1px solid ${black};
    &::before {
      width: 15px;
      border-right: 1px solid ${black};
    }
    &::after {
      width: 15px;
      border-left: 1px solid ${black};
    }
  }
`;

export const Content = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  margin: 0 60px;
  border-right: 2px solid ${black};
  border-left: 2px solid ${black};
  background-color: ${white};
  z-index: 1;
  @media (max-width: 991px) {
    margin: 0 15px;
    border-right: 1px solid ${black};
    border-left: 1px solid ${black};
  }
`;

export const Observer = styled.div`
  position: absolute;
  display: flex;
  top: 60px;
  bottom: 60px;
  right: 0;
  left: 0;
  transition: all 0.5s ease-out;
  will-change: auto;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 60px;
    background-color: ${red};
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 60px;
    background-color: ${gray};
  }
  @media (max-width: 991px) {
    top: 30px;
    bottom: 30px;
    &::before {
      width: 15px;
    }
    &::after {
      width: 15px;
    }
  }
`;

export const BottomBorder = styled(TopBorder)`
  border-top: 2px solid ${black};
  border-bottom: none;
  @media (max-width: 991px) {
    border-top: 1px solid ${black};
  }
`;
