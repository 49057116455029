import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';

import { palette, fonts } from '@/theme';

const duration = 0.3;

export const Button = styled.button.attrs(() => ({ type: 'button' }))`
  display: flex;
  align-items: center;
  height: 30px;
  width: 50%;
  ${fonts.smallText22}
  border-bottom: 1px solid ${palette.black};
  padding: 0 8px;
  @media (min-width: 992px) {
    display: none;
  }
`;

export const CollapseBox = styled(motion.div).attrs(({ $isOpen }) => ({
  animate: {
    height: $isOpen ? 'calc(100% - 30px)' : 0,
    overflowY: $isOpen ? 'auto' : 'hidden',
  },
  transition: {
    height: { duration },
    overflowY: { delay: $isOpen ? duration : 0 },
  },
}))`
  position: fixed;
  top: 30px;
  left: 0;
  right: 0;
  height: 0;
  display: flex;
  flex-direction: column;
  background-color: ${palette.white};
  overflow-y: hidden;
  @media (min-width: 992px) {
    display: none;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 300px;
  width: 100%;
`;

export const Item = styled(Link)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  ${fonts.smallText22}
  text-decoration: none;
  padding: 0 8px;
  box-shadow: ${({ $isBottom, disabled }) =>
    $isBottom ? `${disabled ? 'inset 0 0 0 3px' : '0 -1px'}` : `${disabled ? 'inset 0 0 0 3px' : '0 1px'}`};
`;
