import React from 'react';
import PropTypes from 'prop-types';

import { Container, Item } from './styles';

const Footer = ({ uri }) => (
  <Container>
    <Item to="/kontakt/" disabled={uri === '/kontakt/'}>
      Kontakt
    </Item>
    <Item to="/statuten/" disabled={uri === '/statuten/'}>
      Statuten
    </Item>
    <Item to="/datenschutz/" disabled={uri === '/datenschutz/'}>
      Datenschutz
    </Item>
    <Item to="/impressum/" disabled={uri === '/impressum/'}>
      Impressum
    </Item>
  </Container>
);

Footer.propTypes = { uri: PropTypes.string.isRequired };

export default Footer;
