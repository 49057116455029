const defaultStyles = `
  font-family: "SemiLight", sans-serif;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
`;

const fonts = {
  large: `
    ${defaultStyles}
    font-size: 150px;
    letter-spacing: -2px;
  `,
  lead: `
    ${defaultStyles}
    font-size: 79px;
    letter-spacing: -1px;
  `,
  text: `
    ${defaultStyles}
    font-size: 45px;
    letter-spacing: -0.6px;
  `,
  smallText22: `
    ${defaultStyles}
    font-size: 22px;
    letter-spacing: -0.3px;
  `,
  smallText13: `
    ${defaultStyles}
    font-size: 13px;
    letter-spacing: -0.1px;
  `,
};

export default fonts;
